// src/pages/customerComponents/ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../styles/ContactForm.css';

const ContactUs = ({ isInHero = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    const serviceID = 'service_nbmlv9d';
    const templateID = 'template_8me0tue';
    const publicKey = 'WDj2Vkb5uvjrLg-D-';

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      address: formData.address,
      message: formData.message,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((result) => {
          console.log('Email sent successfully:', result.text);
          setSubmitted(true);
          // Reset form after submission
          setFormData({ name: '', email: '', phone: '', address: '', message: '' });
      }, (error) => {
          console.error('Email sending error:', error.text);
          alert('An error occurred while sending your message. Please try again later.');
      });
  };

  // Content to render (either the form or the thank-you message)
  const content = submitted ? (
    <div className="contact-form">
      <div className="thank-you-message">
        <h1>Thank You!</h1>
        <p>Your message has been sent. We will get back with you shortly.</p>
      </div>
    </div>
  ) : (
    <div className="contact-form">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        {/* Name Field */}
        <div>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        {/* Email Field */}
        <div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        {/* Phone Field */}
        <div>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        {/* Address Field */}
        <div>
          <input
            type="text"
            id="address"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        {/* Message Field */}
        <div>
          <textarea
            id="message"
            name="message"
            placeholder="How Can We Help?"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        {/* Submit Button */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );

  // If the form is inside the Hero component
  if (isInHero) {
    return content;
  }

  // If the form is rendered on its own (at /contact)
  return (
    <div className="contact-page-wrapper">
      {content}
    </div>
  );
};

export default ContactUs;
