// context/AuthContext.js
import React, { useState, useEffect } from "react";
import Api from "../utils/Api";
import { jwtDecode } from "jwt-decode";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  // Check for token in localStorage on initial load
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      Api.token = token;
      const decoded = jwtDecode(token);
      setUser({ id: decoded.id, email: decoded.email, role: decoded.role });
    }
  }, []);

  const login = async (email, password) => {
    try {
      const token = await Api.login({ email, password });
      localStorage.setItem("token", token);
      Api.token = token;
      const decoded = jwtDecode(token);
      setUser({ id: decoded.id, email: decoded.email, role: decoded.role });
    } catch (err) {
      throw err;
    }
  };

  const logout = () => {
    Api.logout();
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
