// src/components/ProcessSection.js
import React from 'react';
import '../../styles/ProcessSection.css';
import { FaClipboardCheck, FaHandshake, FaGavel, FaHammer, FaSmile } from 'react-icons/fa';

function ProcessSection() {
  const steps = [
    {
      number: '1',
      title: 'Inspection',
      description:
        'If storm damage is identified, we assist the homeowner in filing a claim with insurance, providing support throughout the process.',
      icon: <FaClipboardCheck />,
    },
    {
      number: '2',
      title: 'Meet Insurance Company',
      description:
        'We represent the homeowner during the insurance company’s assessment, ensuring all damages are properly evaluated.',
      icon: <FaHandshake />,
    },
    {
      number: '3',
      title: 'Fight for Approval',
      description:
        'Our team advocates for complete coverage, addressing any items the insurance company may have initially overlooked.',
      icon: <FaGavel />,
    },
    {
      number: '4',
      title: 'Build Process',
      description:
        'Upon approval, we manage the entire build process, from material delivery to installation and cleanup.',
      icon: <FaHammer />,
    },
    {
      number: '5',
      title: 'Post-Build',
      description:
        'We ensure customer satisfaction post-build and handle any remaining interactions with the insurance company.',
      icon: <FaSmile />,
    },
  ];

  return (
    <section className="process-section">
      <h2>Our Roofing Process</h2>
      <p className='title-add'>Our contractors strive to give a seamless and stress-free experience for our customers.</p>
      <div className="process-steps">
        {steps.map((step) => (
          <div key={step.number} className="process-step">
            <div className="step-icon">{step.icon}</div>
            <div className="step-content">
              <h3>
                {step.title}
              </h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProcessSection;

