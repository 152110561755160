// src/components/GetStarted.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/GetStarted.css';

function GetStarted() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/contact'); // Adjust the path if your contact page has a different route
  };

  return (
    <section className="get-started">
      <div className="get-started-content">
        <h2>Ready to get started? Request a free inspection today!</h2>
        <button onClick={handleGetStartedClick}>Get Started</button>
      </div>
    </section>
  );
}

export default GetStarted;
