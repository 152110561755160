import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../utils/Api";

function CustomerDetail() {
  const [customer, setCustomer] = useState(null);
  const { id: customerId } = useParams();

  useEffect(() => {
    async function fetchCustomer() {
      try {
        const customer = await Api.getCustomer(customerId);
        setCustomer(customer);
      } catch (err) {
        console.error(err);
      }
    }
    fetchCustomer();
  }, [customerId]);

  if (!customer) return <div>Loading...</div>;

  return (
    <div className="customer-detail">
      <h1>{customer.name}</h1>
      <p>Email: {customer.email}</p>
      <p>Phone: {customer.phone}</p>
      <p>Status: {customer.status}</p>
      {/* Display more customer details as needed */}
    </div>
  );
}

export default CustomerDetail;
