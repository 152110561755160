import  React from 'react';
import '../../styles/ReviewsGroup.css';

function ReviewsGroup() {
    return (
      <div className="reviews-group-container">
        
        <div class="elfsight-app-c90c438f-55bc-4801-8970-f645df6b0044" data-elfsight-app-lazy></div>
      </div>
    );
  }

export default ReviewsGroup;