import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutUs from "../pages/customerComponents/AboutUs";
import ContactForm from "../pages/customerComponents/ContactForm";
import LoginPage from "../pages/LoginPage";
import Dashboard from "../pages/employeeComponents/Dashboard";
import CustomerPortal from "../pages/employeeComponents/CustomerPortal";
import CustomerDetail from "../pages/employeeComponents/CustomerDetail";
import SalesmanDashboard from "../pages/employeeComponents/SalesmanDashboard";
import AdminAccounting from "../pages/employeeComponents/AdminAccounting";
import AdminOverview from "../pages/employeeComponents/AdminOverview";
import NotFound from "../pages/NotFound";
import ProtectedRoute from "../components/ProtectedRoute";
import ResidentialServices from "../pages/customerComponents/servicesComponents/ResidentialServices";
import CommercialServices from "../pages/customerComponents/servicesComponents/CommercialServices";
import SolarServices from "../pages/customerComponents/servicesComponents/SolarServices";
import Gallery from "../pages/customerComponents/Gallery"
import Signup from "../pages/Signup";


function AppRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact" element={<ContactForm />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/services/residential-services" element={<ResidentialServices />} />
      <Route path="/services/commercial-services" element={<CommercialServices />} />
      <Route path="/services/solar" element={<SolarServices />} />
      <Route path="/about-us/gallery" element={<Gallery />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/customers" element={<CustomerPortal />} />
        <Route path="/customers/:id" element={<CustomerDetail />} />
        <Route path="/salesman-dashboard" element={<SalesmanDashboard />} />
        <Route path="/admin/accounting" element={<AdminAccounting />} />
        <Route path="/admin/overview" element={<AdminOverview />} />
      </Route>

      {/* Catch-all Route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;

