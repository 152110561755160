import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

class Api {
  // The token for interacting with the API will be stored here.
  static token;

  /** Generic method to make API requests.
   *
   * @param {string} endpoint - The API endpoint.
   * @param {object} [data={}] - The data to send with the request.
   * @param {string} [method="get"] - The HTTP method.
   * @returns {Promise<object>} - The data from the API.
   */
  static async request(endpoint, data = {}, method = "get") {
    console.debug("API Call:", endpoint, data, method);

    const url = `${BASE_URL}/${endpoint}`;
    const headers = Api.token ? { Authorization: `Bearer ${Api.token}` } : {};
    const params = method === "get" ? data : {};

    try {
      const response = await axios({ url, method, data, params, headers });
      return response.data;
    } catch (err) {
      console.error("API Error:", err.response);
      const message = err.response?.data?.error?.message || err.message;
      throw Array.isArray(message) ? message : [message];
    }
  }

  // ** Authentication Routes **

  /** Log in a user and store the token.
   *
   * @param {object} data - { email, password }
   * @returns {Promise<string>} - JWT token.
   */
  static async login(data) {
    const res = await this.request("auth/token", data, "post");
    Api.token = res.token;
    return res.token;
  }

  /** Register a new user (salesman) and store the token.
   *
   * @param {object} data - { name, email, password, phone }
   * @returns {Promise<string>} - JWT token.
   */
  static async register(data) {
    const res = await this.request("auth/register", data, "post");
    Api.token = res.token;
    return res.token;
  }

  /** Log out a user by clearing the token. */
  static logout() {
    Api.token = null;
  }

  // ** Salesman Routes **

  /** Get a list of all salesmen.
   *
   * @returns {Promise<Array>} - List of salesmen.
   */
  static async getSalesmen() {
    const res = await this.request("salesmen");
    return res.salesmen;
  }

  /** Get details of a specific salesman by ID.
   *
   * @param {number} id - Salesman ID.
   * @returns {Promise<object>} - Salesman details.
   */
  static async getSalesman(id) {
    const res = await this.request(`salesmen/${id}`);
    return res.salesman;
  }

  // ** Customer Routes **

  /** Get a list of all customers.
   *
   * @returns {Promise<Array>} - List of customers.
   */
  static async getCustomers() {
    const res = await this.request("customers");
    return res.customers;
  }

  /** Get details of a specific customer by ID.
   *
   * @param {number} id - Customer ID.
   * @returns {Promise<object>} - Customer details.
   */
  static async getCustomer(id) {
    const res = await this.request(`customers/${id}`);
    return res.customer;
  }

  /** Create a new customer.
   *
   * @param {object} data - Customer data.
   * @returns {Promise<object>} - New customer details.
   */
  static async createCustomer(data) {
    const res = await this.request("customers", data, "post");
    return res.customer;
  }

  // ** Job Routes **

  /** Get a list of all jobs.
   *
   * @returns {Promise<Array>} - List of jobs.
   */
  static async getJobs() {
    const res = await this.request("jobs");
    return res.jobs;
  }

  /** Get details of a specific job by ID.
   *
   * @param {number} id - Job ID.
   * @returns {Promise<object>} - Job details.
   */
  static async getJob(id) {
    const res = await this.request(`jobs/${id}`);
    return res.job;
  }

  /** Create a new job.
   *
   * @param {object} data - Job data.
   * @returns {Promise<object>} - New job details.
   */
  static async createJob(data) {
    const res = await this.request("jobs", data, "post");
    return res.job;
  }

  // ** Supplementer Routes **

  /** Get a list of all supplementers.
   *
   * @returns {Promise<Array>} - List of supplementers.
   */
  static async getSupplementers() {
    const res = await this.request("supplementers");
    return res.supplementers;
  }

  /** Get details of a specific supplementer by ID.
   *
   * @param {number} id - Supplementer ID.
   * @returns {Promise<object>} - Supplementer details.
   */
  static async getSupplementer(id) {
    const res = await this.request(`supplementers/${id}`);
    return res.supplementer;
  }

  /** Create a new supplementer.
   *
   * @param {object} data - Supplementer data.
   * @returns {Promise<object>} - New supplementer details.
   */
  static async createSupplementer(data) {
    const res = await this.request("supplementers", data, "post");
    return res.supplementer;
  }

  // ** Accounts Receivable Routes **

  /** Get a list of all accounts receivable entries.
   *
   * @returns {Promise<Array>} - List of accounts receivable.
   */
  static async getAccountsReceivable() {
    const res = await this.request("accounts/receivable");
    return res.receivables;
  }

  /** Get details of a specific accounts receivable entry by ID.
   *
   * @param {number} id - Entry ID.
   * @returns {Promise<object>} - Accounts receivable details.
   */
  static async getAccountsReceivableEntry(id) {
    const res = await this.request(`accounts/receivable/${id}`);
    return res.receivable;
  }

  /** Create a new accounts receivable entry.
   *
   * @param {object} data - Accounts receivable data.
   * @returns {Promise<object>} - New entry details.
   */
  static async createAccountsReceivableEntry(data) {
    const res = await this.request("accounts/receivable", data, "post");
    return res.receivable;
  }

  // ** Accounts Payable Routes **

  /** Get a list of all accounts payable entries.
   *
   * @returns {Promise<Array>} - List of accounts payable.
   */
  static async getAccountsPayable() {
    const res = await this.request("accounts/payable");
    return res.payables;
  }

  /** Get details of a specific accounts payable entry by ID.
   *
   * @param {number} id - Entry ID.
   * @returns {Promise<object>} - Accounts payable details.
   */
  static async getAccountsPayableEntry(id) {
    const res = await this.request(`accounts/payable/${id}`);
    return res.payable;
  }

  /** Create a new accounts payable entry.
   *
   * @param {object} data - Accounts payable data.
   * @returns {Promise<object>} - New entry details.
   */
  static async createAccountsPayableEntry(data) {
    const res = await this.request("accounts/payable", data, "post");
    return res.payable;
  }

  // ** Additional Methods **

  /** Update a customer's information.
   *
   * @param {number} id - Customer ID.
   * @param {object} data - Updated customer data.
   * @returns {Promise<object>} - Updated customer details.
   */
  static async updateCustomer(id, data) {
    const res = await this.request(`customers/${id}`, data, "patch");
    return res.customer;
  }

  /** Update a job's information.
   *
   * @param {number} id - Job ID.
   * @param {object} data - Updated job data.
   * @returns {Promise<object>} - Updated job details.
   */
  static async updateJob(id, data) {
    const res = await this.request(`jobs/${id}`, data, "patch");
    return res.job;
  }

  /** Delete a customer.
   *
   * @param {number} id - Customer ID.
   * @returns {Promise<object>} - Confirmation message.
   */
  static async deleteCustomer(id) {
    const res = await this.request(`customers/${id}`, {}, "delete");
    return res;
  }

  /** Delete a job.
   *
   * @param {number} id - Job ID.
   * @returns {Promise<object>} - Confirmation message.
   */
  static async deleteJob(id) {
    const res = await this.request(`jobs/${id}`, {}, "delete");
    return res;
  }
}

export default Api;
