import React from "react";

function Dashboard() {
  return (
    <div className="dashboard">
      <h1>Company Dashboard</h1>
      {/* Add components and content specific to the dashboard */}
    </div>
  );
}

export default Dashboard;
