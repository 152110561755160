// components/Navbar.js
import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "../styles/Navbar.css";
import Logo from "../assets/ARSSLogo.png";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const { user, logout } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const menuRef = useRef();
  const servicesRef = useRef();
  const aboutRef = useRef();

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initialize the isMobile state
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.matches(".menu-button")
      ) {
        setMenuOpen(false);
      }
      if (
        servicesRef.current &&
        !servicesRef.current.contains(event.target) &&
        !event.target.closest(".services-menu")
      ) {
        setServicesOpen(false);
      }
      if (
        aboutRef.current &&
        !aboutRef.current.contains(event.target) &&
        !event.target.closest(".about-menu")
      ) {
        setAboutOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <Link to={user ? "/dashboard" : "/"}>
          <img src={Logo} alt="Company Logo" className="nav-logo" />
        </Link>
      </div>
      {isMobile ? (
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      ) : null}
      <ul className={`nav-links ${isMobile ? "mobile" : ""} ${mobileMenuOpen ? "open" : ""}`}>
        {!user && (
          <>
            <li>
              <NavLink exact to="/" onClick={() => setMobileMenuOpen(false)}>Home</NavLink>
            </li>
            <li
              className={`about-menu ${isMobile ? "" : "right-dropdown"}`}
              onClick={() => isMobile && setAboutOpen(!aboutOpen)}
              onMouseEnter={() => !isMobile && setAboutOpen(true)}
              onMouseLeave={() => !isMobile && setAboutOpen(false)}
            >
              <span className="about-link">About Us</span>
              {(aboutOpen) && (
                <ul className={`about-dropdown ${isMobile ? "mobile" : ""}`} ref={aboutRef}>
                  <li>
                    <NavLink to="/about-us" onClick={() => setMobileMenuOpen(false)}>Our Company</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us/gallery" onClick={() => setMobileMenuOpen(false)}>Recent Projects</NavLink>
                  </li>
                  <li>
                    <NavLink to="/login" onClick={() => setMobileMenuOpen(false)}>Employee Login</NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li
              className={`services-menu ${isMobile ? "" : "right-dropdown"}`}
              onClick={() => isMobile && setServicesOpen(!servicesOpen)}
              onMouseEnter={() => !isMobile && setServicesOpen(true)}
              onMouseLeave={() => !isMobile && setServicesOpen(false)}
            >
              <span className="services-link">Services</span>
              {(servicesOpen) && (
                <ul className={`services-dropdown ${isMobile ? "mobile" : ""}`} ref={servicesRef}>
                  <li>
                    <NavLink to="/services/residential-services" onClick={() => setMobileMenuOpen(false)}>Residential Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services/commercial-services" onClick={() => setMobileMenuOpen(false)}>Commercial Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services/solar" onClick={() => setMobileMenuOpen(false)}>Solar</NavLink>
                  </li>
                </ul>
              )}
            </li>
          </>
        )}
        {user && (
          <>
            <button onClick={toggleMenu} className="menu-button">
              Menu
            </button>
            {menuOpen && (
              <ul className="dropdown-menu" ref={menuRef}>
                <li>
                  <NavLink exact to="/dashboard" onClick={toggleMenu}>
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/customers" onClick={toggleMenu}>
                    Customer Portal
                  </NavLink>
                </li>
                {(user.role === "salesman" ||
                  user.role === "admin" ||
                  user.role === "developer") && (
                  <li>
                    <NavLink
                      exact
                      to="/salesman-dashboard"
                      onClick={toggleMenu}
                    >
                      Salesman Dashboard
                    </NavLink>
                  </li>
                )}
                {(user.role === "admin" || user.role === "developer") && (
                  <>
                    <li>
                      <NavLink
                        exact
                        to="/admin/accounting"
                        onClick={toggleMenu}
                      >
                        Accounting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/admin/overview"
                        onClick={toggleMenu}
                      >
                        Business Overview
                      </NavLink>
                    </li>
                  </>
                )}
                <li>
                  <button
                    onClick={() => {
                      toggleMenu();
                      logout();
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            )}
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
