// src/components/SolarServices.js
import React from 'react';
import '../../../styles/SolarServices.css';
import { useNavigate } from 'react-router-dom';

function SolarServices() {
  const solarComponents = [
    {
      title: 'Jinko Eagle Continental Panels 380W',
      description:
        'We use high-efficiency Jinko Eagle Continental Panels rated at 380W. These panels are known for their durability and excellent performance, even in low-light conditions. They maximize energy production to ensure you get the most out of your solar system.',
    },
    {
      title: 'Duracell or Enphase Microinverters',
      description:
        'Our systems utilize Duracell or Enphase microinverters, which convert the DC power generated by the solar panels into usable AC power for your home. Microinverters increase system efficiency and offer greater flexibility and reliability compared to traditional inverters.',
    },
    {
      title: 'Energy Storage Solutions',
      description:
        "Pair your solar panels with Duracell or Enphase storage batteries to store excess energy generated during the day. This allows you to use solar power even when the sun isn't shining, providing backup power during outages and further reducing your reliance on the grid.",
    },
    {
      title: 'Accessory Components',
      description:
        'We offer a range of accessory components to enhance your solar system, including mounting hardware, monitoring systems, and more. Our team ensures every part of your solar installation is optimized for performance and longevity.',
    },
  ];

  const navigate = useNavigate();
  const handleContactNav = () => {
    navigate('/contact');
  };

  return (
    <div className="solar-page">
      {/* Header Section */}
      <section className="solar-header">
        <div className="header-text">
          <h1>Harness the Power of the Sun</h1>
          <h2>Innovative Solar Solutions for Your Home</h2>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="solar-intro">
        <div className="intro-content">
          <h2>Why Go Solar?</h2>
          <p>
            Solar roofing allows you to generate your own clean, renewable energy, reducing your electricity bills and carbon footprint. With advances in technology, solar panels have become more efficient and affordable than ever. Investing in solar energy not only benefits the environment but also increases your home's value.
          </p>
        </div>
      </section>

      {/* Components and Video Section Side by Side */}
      <section className="solar-content">
        <div className="content-container">
          {/* Solar Components (Collapsible Sections) */}
          <div className="solar-components">
            <h2>Our Solar Solutions</h2>
            <div className="components-list">
              {solarComponents.map((component, index) => (
                <ComponentItem key={index} component={component} />
              ))}
            </div>
          </div>

          {/* Video Section */}
          <div className="solar-video">
            <div className="video-content">
              <h2>Learn More About the Enphase Home Energy Solution</h2>
              {/* Embedded YouTube Video */}
              <div className="video-wrapper">
                <iframe
                  src="https://www.youtube.com/embed/cmvacfke1mY"
                  title="Enphase Home Energy Solution"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="solar-cta">
        <div className="cta-content">
          <h2>Ready to Embrace Solar Energy?</h2>
          <p>
            Contact us today for a free consultation and discover how our solar solutions can benefit your home and the environment.
          </p>
          <button onClick={handleContactNav} className="cta-button">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}

function ComponentItem({ component }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="component-item">
      <div className="component-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{component.title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="component-details">
          <p>{component.description}</p>
        </div>
      )}
    </div>
  );
}

export default SolarServices;
