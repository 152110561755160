// src/components/Gallery.js
import React, { useEffect } from 'react';

function Gallery() {
  useEffect(() => {
    // Check if the script is already loaded
    const scriptId = 'companycam-showcase-script';
    if (!document.getElementById(scriptId)) {
      const ccShowcaseRoot = document.getElementById('companycam-showcase-root');
      if (!ccShowcaseRoot || !ccShowcaseRoot.attachShadow || !window.fetch) {
        return;
      }
      const ccShowcaseScript = document.createElement('script');
      ccShowcaseScript.id = scriptId;
      ccShowcaseScript.src = 'https://showcase.companycam.com/bundle.js';
      ccShowcaseScript.type = 'text/javascript';
      ccShowcaseScript.async = true;
      document.body.appendChild(ccShowcaseScript);
    }
  }, []); // Empty dependency array ensures this runs once after mount

  return (
    <div>
      <div
        data-showcase-id="5f0c8cea-0b4e-4be6-b0d2-7ed8080fe14a"
        id="companycam-showcase-root"
      ></div>
    </div>
  );
}

export default Gallery;
