// src/components/NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/NotFound.css';

function NotFound() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Adjust the path to your home page if necessary
  };

  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Oops! Page not found</h2>
        <p>The page you're looking for doesn't exist or has been moved.</p>
        <button onClick={handleGoHome}>Go Home</button>
      </div>
    </div>
  );
}

export default NotFound;
