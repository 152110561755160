// src/components/AboutUs.js
import React, { useState } from 'react';
import '../../styles/AboutUs.css';

function AboutUs() {
  const sections = [
    {
      id: 'aboutUs',
      title: 'About Us',
      content: (
        <p className="aboutus-text">
          Welcome to <strong>Affordable Roofing + Solar Solutions</strong>, a small,
          family-run business dedicated to providing top-notch roofing services in the
          Louisville, KY Metro area and surrounding cities. With over{' '}
          <strong>25 years of combined roofing experience</strong>, we pride ourselves on
          delivering exceptional workmanship and customer satisfaction.
        </p>
      ),
    },
    {
      id: 'ourStory',
      title: 'Our Story',
      content: (
        <p className="aboutus-text">
          Our journey began with a simple mission: to offer reliable and affordable roofing
          solutions to homeowners and businesses alike. As a family-owned company, we
          understand the importance of trust and integrity. That's why we treat every project
          as if it were our own home, ensuring that each job is completed to the highest
          standards.
        </p>
      ),
    },
    {
      id: 'whyChooseUs',
      title: 'Why Choose Us',
      content: (
        <ul className="aboutus-list">
          <li>
            <strong>Expertise in Insurance Claims:</strong> We specialize in handling
            insurance claims to help keep roofing costs down for the typical homeowner.
          </li>
          <li>
            <strong>A+ Rating on BBB & 5-Star Google Reviews:</strong> Our commitment to
            excellence has earned us an A+ rating with the Better Business Bureau and
            numerous 5-star reviews on Google.
          </li>
          <li>
            <strong>Projects of All Sizes:</strong> No job is too big or too small for us.
          </li>
          <li>
            <strong>Expertly Trained Inspections:</strong> Our team is highly knowledgeable
            in storm damage assessment.
          </li>
        </ul>
      ),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, sections.length - 1));
  };

  return (
    <div className="aboutus-page">
      <div className="all-content">
        {/* Hero Section */}
        <section className="aboutus-hero">
          <div className="aboutus-hero-text">
            <h1 className="aboutus-hero-title">Affordable Roofing + Solar Solutions</h1>
            <h2 className="aboutus-hero-tagline">"A Job Done Right"</h2>
          </div>
        </section>

        {/* Middle Section */}
        <section className="aboutus-middle">
          <div className="aboutus-carousel">
            <div
              className="carousel-track"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {sections.map((section, index) => (
                <div key={index} className="aboutus-card">
                  <div className="aboutus-content">
                    <h2 className="aboutus-title">{section.title}</h2>
                    {section.content}
                  </div>
                </div>
              ))}
            </div>
            {/* Navigation Arrows */}
            {currentIndex > 0 && (
              <button className="carousel-button prev" onClick={handlePrevClick}>
                &#8249;
              </button>
            )}
            {currentIndex < sections.length - 1 && (
              <button className="carousel-button next" onClick={handleNextClick}>
                &#8250;
              </button>
            )}
          </div>
        </section>

        {/* Contact Section */}
        <section className="aboutus-contact">
          <div className="aboutus-container">
            <h2 className="aboutus-title">Get in Touch</h2>
            <p className="aboutus-contact-info">
              <strong>Email:</strong>{' '}
              <a href="mailto:roofs@affordableroofing4me.com">
                roofs@affordableroofing4me.com
              </a>
            </p>
            <p className="aboutus-contact-info">
              <strong>Phone:</strong> <a href="tel:502-539-8056">502-539-8056</a>
            </p>
            <p className="aboutus-text">
              We proudly serve the <strong>Louisville, KY Metro area</strong>, including all
              surrounding cities. Our local presence allows us to respond quickly to your needs and
              provide personalized service.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUs;
