import React from 'react';
import HeroSection from './customerComponents/HeroSection.js';
import ServicesSection from './customerComponents/ServicesSection';
import ProcessSection from './customerComponents/ProcessSection';
import GetStarted from './customerComponents/GetStarted.js';
import ReviewsGroup from './customerComponents/ReviewsGroup';


function HomePage() {
  return (
    <div>
      <HeroSection />
      <ReviewsGroup /> 
      <ServicesSection />
      <GetStarted />
      <ProcessSection />
      
    </div>
  );
}

export default HomePage;
