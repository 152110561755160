import React, { useState, useEffect } from "react";
import Api from "../../utils/Api";

function AdminAccounting() {
  const [payables, setPayables] = useState([]);
  const [receivables, setReceivables] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const payables = await Api.getAccountsPayable();
        const receivables = await Api.getAccountsReceivable();
        setPayables(payables);
        setReceivables(receivables);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="admin-accounting">
      <h1>Admin Accounting</h1>
      <h2>Accounts Payable</h2>
      <ul>
        {payables.map((payable) => (
          <li key={payable.id}>
            {payable.invoice_type}: ${payable.amount} paid to {payable.paid_to}
          </li>
        ))}
      </ul>
      <h2>Accounts Receivable</h2>
      <ul>
        {receivables.map((receivable) => (
          <li key={receivable.id}>
            {receivable.payment_type}: ${receivable.amount} from customer ID{" "}
            {receivable.customer_id}
          </li>
        ))}
      </ul>
      {/* Add forms to input new invoices and checks */}
    </div>
  );
}

export default AdminAccounting;
