// src/components/CommercialServices.js
import React from 'react';
import '../../../styles/CommercialServices.css';
import { useNavigate } from 'react-router-dom';

function CommercialServices() {
  const navigate = useNavigate();
  const handleContactNav = () => {
    navigate('/contact');
  };
  
  const services = [
    {
      title: 'EPDM Roofing',
      description:
        'We specialize in EPDM roofing solutions, offering durable and cost-effective options for your commercial building. EPDM roofs are known for their longevity and resistance to extreme weather conditions.',
    },
    {
      title: 'TPO Roofing',
      description:
        'Our TPO roofing systems provide energy efficiency and high performance. TPO is a popular choice for commercial roofs due to its reflective properties and resistance to UV radiation.',
    },
    {
      title: 'Flat Roof Services',
      description:
        'We offer expert installation, repair, and maintenance of flat roofing systems. Our team ensures your flat roof is properly installed and maintained for optimal performance.',
    },
    {
      title: 'Roof Repair',
      description:
        'Our skilled professionals provide prompt and reliable roof repair services to address leaks, storm damage, and wear and tear, extending the life of your commercial roof.',
    },
    {
      title: 'Roof Replacement',
      description:
        'When repair is no longer sufficient, we offer complete roof replacement services. We work with you to select the best roofing system for your building and budget.',
    },
    {
      title: 'Roof Maintenance',
      description:
        'Regular maintenance is key to prolonging the life of your roof. Our maintenance programs are tailored to your needs, ensuring your roof remains in top condition.',
    },
  ];

  return (
    <div className="commercial-page">
      {/* Header Section */}
      <section className="commercial-header">
        <div className="header-text">
          <h1>Professional Commercial Roofing Services</h1>
          <h2>Quality Solutions for Your Business</h2>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="commercial-intro">
        <div className="intro-content">
          <h2>Why Choose Affordable Roofing + Solar Solutions?</h2>
          <p>
            At Affordable Roofing + Solar Solutions, we provide expert commercial roofing services designed to meet the unique needs of your business. With our commitment to quality and customer satisfaction, we ensure your commercial property is protected with the best roofing solutions available. Here's why businesses trust us:
          </p>
          <ul className="intro-list">
            <li>Expertise in commercial roofing systems and materials.</li>
            <li>Skilled and certified professionals for every project.</li>
            <li>Timely project completion with minimal disruption to your business.</li>
            <li>Exceptional customer service and transparent communication.</li>
            <li>Comprehensive warranties for peace of mind.</li>
          </ul>
        </div>
      </section>

      {/* Services Section */}
      <section className="commercial-services">
        <div className="services-content">
          <h2>Our Commercial Roofing Services</h2>
          <div className="services-list">
            {services.map((service, index) => (
              <ServiceItem key={index} service={service} />
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="commercial-cta">
        <div className="cta-content">
          <h2>Protect Your Business with Quality Roofing</h2>
          <p>
            Contact us today for a free consultation and let our experts provide you with the best roofing solutions for your commercial property.
          </p>
          <button onClick={handleContactNav} className="cta-button">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}

function ServiceItem({ service }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="service-item">
      <div className="service-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{service.title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="service-details">
          <p>{service.description}</p>
        </div>
      )}
    </div>
  );
}

export default CommercialServices;
