// src/components/ResidentialServices.js
import React from 'react';
import '../../../styles/ResidentialServices.css';
import { useNavigate } from 'react-router-dom';

function ResidentialServices() {
  const navigate = useNavigate();
  const handleContactNav = () => {
    navigate('/contact');
  };

  const services = [
    {
      title: 'Shingle Roofing',
      description:
        'We specialize in installing high-quality shingle roofs. Partnered with Owens Corning, we offer a variety of durable and aesthetically pleasing shingles.',
      action: {
        label: 'Design Your Roof',
        href: 'https://roofvisualizer.owenscorning.com/#',
      },
    },
    {
      title: 'Metal Roofing',
      description:
        "Our metal roofing solutions provide longevity and energy efficiency, available in various styles and colors to match your home's design.",
    },
    {
      title: 'Flat Roofing',
      description:
        'We offer expert installation and repair of flat roofing systems, including EPDM, TPO, and Modified Bitumen, ensuring durability and performance.',
    },
    {
      title: 'Siding',
      description:
        "Enhance your home's curb appeal and energy efficiency with our professional siding installation and repair services. We work with various materials to suit your style and budget.",
    },
    {
      title: 'Gutters',
      description:
        'Protect your home from water damage with our gutter installation and maintenance services. We ensure proper drainage to keep your foundation and landscaping safe.',
    },
    {
      title: 'Insulation',
      description:
        "Improve your home's energy efficiency with our insulation services. We help you maintain comfortable temperatures year-round while reducing energy costs.",
    },
    {
      title: 'Painting',
      description:
        'Our professional painting services refresh the look of your home, both interior and exterior. We use high-quality paints and techniques for a flawless finish.',
    },
    {
      title: 'Additional Services',
      description:
        'From roof inspections and maintenance to custom home improvements, we offer a wide range of services to meet all your residential needs.',
    },
  ];

  return (
    <div className="residential-page">
      {/* Header Section */}
      <section className="residential-header">
        <div className="header-text">
          <h1>Expert Residential Services</h1>
          <h2>Your One-Stop Solution for Home Improvements</h2>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="residential-intro">
        <div className="intro-content">
          <h2>Why Choose Affordable Roofing + Solar Solutions?</h2>
          <p>
            At Affordable Roofing + Solar Solutions, we are committed to providing top-notch residential services that enhance the beauty, value, and functionality of your home. With our extensive experience and customer-first approach, we ensure that every project is completed to the highest standards. Here's why homeowners trust us:
          </p>
          <ul className="intro-list">
            <li>Expertise in insurance claims to minimize out-of-pocket costs.</li>
            <li>High-quality materials and partnerships with industry leaders.</li>
            <li>Skilled and certified professionals for every service.</li>
            <li>Exceptional customer service and transparent communication.</li>
            <li>Comprehensive warranties for peace of mind.</li>
          </ul>
        </div>
      </section>

      {/* Services Section */}
      <section className="residential-services">
        <div className="services-content">
          <h2>Our Residential Services</h2>
          <div className="services-list">
            {services.map((service, index) => (
              <ServiceItem key={index} service={service} />
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="residential-cta">
        <div className="cta-content">
          <h2>Ready to Transform Your Home?</h2>
          <p>
            Contact us today for a free consultation and let our experts help you bring your vision to life. Your dream home is just a call away!
          </p>
          <button onClick={handleContactNav} className="cta-button">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}

function ServiceItem({ service }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="service-item">
      <div className="service-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{service.title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="service-details">
          <p>{service.description}</p>
          {service.action && (
            <a
              className="service-button"
              href={service.action.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {service.action.label}
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default ResidentialServices;
