import React, { useState, useEffect, useContext } from "react";
import Api from "../../utils/Api";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";

function SalesmanDashboard() {
  const { user } = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const allCustomers = await Api.getCustomers();
        const myCustomers = allCustomers.filter(
          (customer) => customer.salesman_id === user.id
        );
        setCustomers(myCustomers);

        // Fetch additional stats if available
        const salesman = await Api.getSalesman(user.id);
        setStats({
          ytdSales: salesman.ytd_sales,
          ytdCommission: salesman.ytd_commission,
          totalComingIn: salesman.total_commission_coming_in,
        });
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [user.id]);

  return (
    <div className="salesman-dashboard">
      <h1>Welcome, {user.email}</h1>
      {stats && (
        <div className="stats">
          <p>YTD Sales: ${stats.ytdSales}</p>
          <p>YTD Commission: ${stats.ytdCommission}</p>
          <p>Total Commission Coming In: ${stats.totalComingIn}</p>
        </div>
      )}
      <h2>Your Customers</h2>
      <ul>
        {customers.map((customer) => (
          <li key={customer.id}>
            <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SalesmanDashboard;
