// src/components/HeroSection.js
import React from 'react';
import '../../styles/HeroSection.css';

function HeroSection() {
  return (
    <section className="hero-section">
      <div className="hero-overlay">
        <div className="hero-content">
          <p>
            <strong>Affordable Roofing + Solar Solutions | Job Done Right</strong>
            <br />
            <br />
            We are an award-winning Louisville roofing company providing residential and commercial roofing services, including roof repair, roof replacements, flat roofs, gutters, and more. With industry-leading training, expert attention to detail, and responsive service, you can trust Affordable Roofing + Solar Solutions to help you get a job done right.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

