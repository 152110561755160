// src/components/ServicesSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/ServicesSection.css';

function ServicesSection() {
  const services = [
    {
      name: 'Solar',
      description: `
        Harness the power of the sun with our advanced solar solutions.
        Our solar installations are designed to maximize energy efficiency and reduce your utility bills.
        With top-quality panels and expert installation, we help you make a sustainable investment for the future.
      `,
      link: '/services/solar',
    },
    {
      name: 'Residential Roofing',
      description: `
        Protect your home with our high-quality residential roofing services.
        We offer a wide range of roofing options to suit your style and budget.
        Our experienced team ensures your roof is installed or repaired with utmost care and professionalism.
      `,
      link: '/services/residential-services',
    },
    {
      name: 'Commercial Roofing',
      description: `
        Reliable commercial roofing solutions for your business.
        We understand the unique needs of commercial properties and provide durable roofing systems that stand the test of time.
        Trust us for efficient service and minimal disruption to your operations.
      `,
      link: '/services/commercial-services',
    },
  ];

  return (
    <section className="services-section">
      <h1 className="services-title">Our Services</h1>
      <p className="services-description">
        We offer a wide variety of services throughout the Louisville Metropolitan Area.
        Whether you're a homeowner or a business owner, our expert team is here to provide top-notch solutions tailored to your needs.
      </p>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-box" key={index}>
            <h2>{service.name}</h2>
            <p>{service.description}</p>
            <Link to={service.link} className="service-link">View More</Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ServicesSection;
