import React, { useState, useEffect } from "react";
import Api from "../../utils/Api";

function AdminOverview() {
  const [totals, setTotals] = useState(null);

  useEffect(() => {
    async function fetchTotals() {
      try {
        // You might need to create an API endpoint to get these totals
        const totals = await Api.getCompanyTotals();
        setTotals(totals);
      } catch (err) {
        console.error(err);
      }
    }
    fetchTotals();
  }, []);

  if (!totals) return <div>Loading...</div>;

  return (
    <div className="admin-overview">
      <h1>Company Overview</h1>
      <p>YTD Sales: ${totals.ytdSales}</p>
      <p>YTD Commission: ${totals.ytdCommission}</p>
      <p>Builder Pay: ${totals.builderPay}</p>
      <p>Materials Pay: ${totals.materialsPay}</p>
      <p>Profit and Loss: ${totals.profitLoss}</p>
      {/* Add forecasting tools and charts */}
    </div>
  );
}

export default AdminOverview;
