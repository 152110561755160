// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import serviceAreaMap from '../assets/serviceareamap.png';
import instagramIcon from '../assets/instagramIcon.png';
import facebookIcon from '../assets/facebookIcon.png';

function Footer() {
  return (
    <footer className="footer">
      {/* Footer Top */}
      <div className="footer-top">
        {/* First Column: Sitemap */}
        <div className="footer-column">
          <h3>Site Map</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/about-us/gallery">Recent Projects</Link></li>
            <li><Link to="/services/residential-services">Residential Services</Link></li>
            <li><Link to="/services/commercial-services">Commercial Services</Link></li>
            <li><Link to="/services/solar">Solar Services</Link></li>
            <li><Link to="/login">Employee Login</Link></li>
          </ul>
        </div>

        {/* Second Column: Service Area Map */}
        <div className="footer-column">
          <h3>Our Service Area</h3>
          <div className="service-area-map">
            <img src={serviceAreaMap} alt="Service Area Map" />
          </div>
        </div>

        {/* Third Column: Contact Info */}
        <div className="footer-column">
          <h3>Contact Us</h3>
          <div className="contact-info">
            <p>
              <a href="tel:5027143138">502-714-3138</a>
            </p>
            <p>
              <a href="mailto:roofs@affordableroofing4me.com">roofs@affordableroofing4me.com</a>
            </p>
            <p>
              9850 Von Allmen Ct, Suite 201, Louisville, KY 40241
            </p>
          </div>
        </div>
      </div>

      {/* Divider Line */}
      <div className="footer-divider"></div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p className="footer-left">
          © {new Date().getFullYear()} Affordable Roofing + Solar Solutions. All rights reserved.
        </p>
        <div className="footer-right">
          <p>Follow our Socials!</p>
          <a
            href="https://www.instagram.com/affordableroofingandsolar/#"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61557984091143&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
